






















































































































@import "~@/assets/styles/helpers/variables";
.loader {
  min-height: 300px;
  justify-content: center;
  align-items: center;
  background: white;
}
.card {
  &-title {
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 0;
  }
  &-sub {
    font-weight: normal;
    font-size: 12px;
    color: #9797ad !important;
  }
  &-t {
    color: #9797ad !important;
    &-title {
      color:#9797ad;
      font-size: 16px;
      font-weight: bold;
    }
    &-type {
      height: 25px;
    }
    span {
      color: #525252;
      font-size: 14px;
    }
  }
  &-links {
    display: block;
    width: 100%;
    background: #efefef;
    padding: 15px 0;
    font-size: 14px;
    &-text{
      color: #5076ff;
      span {
        padding: 0 10px;
        cursor: pointer;
        font-size: 14px;
        &:hover{
          color: #708efc;
        }
      }
      span.ex:hover{
          color: red !important;
        
      }
    }
  }
}

 .table  {
   padding: 10px;
  &-title {
    background: $colorPrimary;
    color: white;
    font-size: 14px;
    font-weight: normal;
    outline: none;
    border-bottom: 0;
    border-radius: 10px 10px 0px 0px;
  }
  &-premio {
    font-size: 12px;
    font-weight: 700;
    text-align: right;
    align-items: left;
  }
  &-b {
    &-ball, .group{
      width: 30px;
      height: 30px;
      margin-right: 5px;
      font-size: 12px;
      border: solid 0.7px 50%;
      color: $colorPrimary;
      border-radius: 50%;
      font-weight: bold;
      transition: all 0.3s;
      justify-content: center;
      align-items: center;
      border: 1px solid $colorPrimary;
      &.group{
        background: $colorPrimary;
        color: white;
      }
    }

    &-content {
      justify-content: center;
      padding: 0 10px;
    }

    &-description {
      font-size: 12px;
      font-weight: 700;
      text-align: right;
      justify-content: flex-end;
    }
  }
}

